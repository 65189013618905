.container{
    padding: 2rem 2rem;
    display: grid;
    grid-template-columns: 1fr 3fr 1fr;
}

.middle{
    display: flex;
    align-items: baseline;
    justify-content: center;
    position: relative;
}

.middle>img{
    width: 25rem;
    position: absolute;
    bottom:0;
}

.whiteCircle{
    width:22rem;
    height: 22rem;
    border-radius: 50%;
    z-index: -99;
    position: absolute;
    bottom: 0;
    background: rgb(0,0,0);
    background: rgb(99, 60, 22);
    background: rgb(99,29,22);
background: linear-gradient(90deg, rgba(99,29,22,0.8318965517241379) 50%, rgba(252,176,69,0.8577586206896551) 100%);
}

.cart2{
    position: absolute;
    bottom: 25%;
    right:5%;
    gap: 1rem;
    align-items: center;
}

.h_sides{
    display: grid;
    grid-template-rows: 1fr 1fr;
}

.text1{
    text-transform: uppercase;
    font-size: 1.5rem;
    width: min-content;
    display: block;
    font-weight: 600;
}

.text2{
    display: flex;
    flex-direction: column;
    color: black;
}

.text2>:first-child{
    font-weight: 800;
    font-size: 2.0rem;
}

.text2>:nth-child(2){
    display: block;
    
}

.services{
    display: flex;
    flex-direction: column;
    text-align: right;
    text-transform: uppercase;
    font-size: 1.5rem;
    font-weight: 600;
    
}

.cart2{
    position: absolute;
    bottom: 25%;
    right: 5%;
    display: flex;
    gap: 1rem;
    align-items: center;
}

/* .cart2>svg{
    width: 24px;
    height: 24px;
    color: black;
    background: white;
    padding:10px;
    border-radius: 50%;
    border: 4px solid var(--black);
} */

.signup{
    display: flex;
    gap: 1rem;
    align-items: center;
    background-color: white;
    padding: 8px;
    font-size: 0.8rem;
    font-weight: 600;
    border-radius: 15px;
    box-shadow: var(--shadow1);
}

.signup>:first-child{
    display: block;
    width: 5rem;
}

.signup>:nth-child(2){
    border-radius: 50%;
    border: 2px solid rgb(99,29,22);
    display: flex;
    width: 18px;
    height: 18px;
    padding: 5px;
    justify-content: center;
    align-items: center;
}

.mission{
    display: flex;
    text-align: right;
    flex-direction: column;
    color: black;
}

.mission>:first-child{
    font-weight: 800;
    font-size: 1.5rem;
    text-transform: uppercase;
}

.mission>:nth-child(2){
    display: block;
}

@media screen and (max-width: 856px) {
    .text1, .text2>span:nth-of-type(2){
        text-align: justify;
        font-size: .9rem;
    }

    .text2 > span:nth-of-type(1),
    .services > span:nth-child(1),
    .mission > span:nth-child(1){
        font-size: 1.5rem;
        text-align: left;
    }

    /* .whiteCircle{
        position: absolute;
        width: 100%;
        height: 40%;
    }

    .middle>img{
        width: 100%;
        height: 50%;
    } */

    .container{
        grid-template-areas:
            'left center center'
            'right right right';
    }

    .container>:first-child{
        grid-area: left;
        grid-template-rows: none;
        gap: 8rem;
    }

    .container>:nth-child(2){
        grid-area: center;
    }

    .container>:nth-child(3){
        grid-area: right; 
        display: flex;
        justify-content: space-around;
        margin: 2rem 0rem;
    }
    
}

@media screen and (max-width: 640px) {
    .whiteCircle{
        top:0;
        width: 16rem;
        height: 90%;
    }  
    
    .middle>img{
        top:3rem;
        width: 12rem;
        height: 15rem;
    }
}